import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutHeader from '../components/About/aboutHeader.js'

const About = () => {

  let intro = 'filler';
  let main = "filler";

  return (
  <Layout>
    <SEO title="About" />
    <AboutHeader heading = {'About'} intro = {intro} main = {main}/>

    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
  )

}

export default About

// pass in template literal, split on '\n\n', make num paragraph as list length
