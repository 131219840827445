import React from "react"
//import styles from './results.module.css'

/* Displays the improvement heading and circular SVG*/

const AboutHeader = props => {

    let paragraphArr = props.main.split('\n');

    console.log(paragraphArr)

    return (
        <div className = "d-flex flex-column align-items-start p-4" style = {{
            margin: '0 auto',
            maxWidth: 800
        }}>
             <h1 className = "text-center text-white" style = {{
            margin: '0 0 1.612rem',
            lineHeight: '1.2',
            fontFamily: "Roboto Condensed",
            fontWeight: '700'
        }}>{props.heading}</h1>

        <p className = "mb-2 text-white" style = {{
            lineHeight: '2',
            fontWeight: '200',
            fontSize: 25
        }}> {props.intro} </p>

        <hr className = 'w-100' style = {{
            backgroundColor: 'white'
        }}></hr>


        {paragraphArr.map(para => <p style = {{
            fontSize: 18,
            color: 'white',
            lineHeight: '1.2'
        }}>{para}</p>)}

        </div>
        
    )
}

export default AboutHeader